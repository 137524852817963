import React, { lazy, Suspense } from "react";
import PageNotFound from "./components/Page Not Found";
import Login from "./Auth/Auth/Login";
import Register from "./Auth/Auth/Register";
const Home = lazy(() => import("./components/Home"));
const Moduler = lazy(() => import("./components/BPMN Container/Moduler"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Documents = lazy(() => import("./components/Documents"));
const Template = lazy(() => import("./components/Template"));
const Execute = lazy(() => import("./components/BPMN Container/execute"));

export const routes = [
  {
    path: "/",
    element: (
      <Suspense fallback={<p>Loading....</p>}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: "/signin",
    element: (
      <Suspense fallback={<p>Loading....</p>}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/signup",
    element: (
      <Suspense fallback={<p>Loading....</p>}>
        <Register />
      </Suspense>
    ),
  },

  {
    path: "/dashboard",
    element: (
      <Suspense fallback={<p>Loading....</p>}>
        <Dashboard />
      </Suspense>
    ),
  },
  {
    path: "/designer",
    element: (
      <Suspense fallback={<p>Loading....</p>}>
        <Moduler />
      </Suspense>
    ),
  },
  {
    path: "/dashboard/documents",

    element: (
      <Suspense fallback={<p>Loading....</p>}>
        <Documents />
      </Suspense>
    ),
  },
  {
    path: "/dashboard/templates",
    element: (
      <Suspense fallback={<p>Loading....</p>}>
        <Template />
      </Suspense>
    ),
  },
  {
    path: "/execute",
    element: (
      <Suspense fallback={<p>Loading....</p>}>
        <Execute />
      </Suspense>
    ),
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];
