import React from "react";
import "../../assests/css/pageNotFound.css";
import { Link } from "react-router-dom";
const PageNotFound = () => {
  return (
    <>
      <section class="error-container">
        <span class="four">
          <span class="screen-reader-text">4</span>
        </span>
        <span class="zero">
          <span class="screen-reader-text">0</span>
        </span>
        <span class="four">
          <span class="screen-reader-text">4</span>
        </span>
      </section>
      <div class="link-container">
        <Link to="/dashboard" class="more-link">
          Dashboard
        </Link>
      </div>
    </>
  );
};

export default PageNotFound;
