import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  CssBaseline,
  Link,
  InputAdornment,
  IconButton,
  Grid,
} from "@mui/material";
import {
  LockOutlined,
  AccountCircleOutlined,
  EmailOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { handleRegistration } from "../../services/Auth/AuthService";

export default function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleRegistration(user);
    console.log("Success:", user);

    navigate("/signin");
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Box display="flex" justifyContent="center" mb={4}>
          <img src={loginsideimg} alt="register" style={{ height: "450px" }} />
        </Box> */}
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                margin="normal"
                prefix={AccountCircleOutlined}
                required
                fullWidth
                id="name"
                label="First Name"
                name="firstName"
                value={user.firstName}
                autoComplete="name"
                autoFocus
                onChange={onChange}
                InputProps={{
                  startAdornment: <AccountCircleOutlined />,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                margin="normal"
                prefix={AccountCircleOutlined}
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                value={user.lastName}
                autoComplete="name"
                autoFocus
                onChange={onChange}
                InputProps={{
                  startAdornment: <AccountCircleOutlined />,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                InputProps={{
                  startAdornment: <EmailOutlined />,
                }}
                id="email"
                label="Email"
                name="email"
                value={user.email}
                autoComplete="email"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                value={user.password}
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={onChange}
                InputProps={{
                  startAdornment: <LockOutlined />,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Register
          </Button>
          <Typography component="p" variant="body2">
            Already have an account?{" "}
            <Link component={NavLink} to="/signin">
              Log in
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
