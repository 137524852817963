import axios from "axios";
export const handleRegistration = async (data) => {
  try {
    const res = await axios.post("url", data);
    console.log("response from Registration", res.data);
  } catch (error) {
    console.log("error", error.message);
  }
};
export const handleLogin = async (user) => {
  try {
    const res = await axios.get("url", { params: user });
    console.log("response from login", res.data);
  } catch (error) {
    console.log("error", error);
  }
};
