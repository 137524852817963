import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  CssBaseline,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  LockOutlined,
  EmailOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { handleLogin } from "../../services/Auth/AuthService";

const theme = createTheme();

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const onChangeData = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const handleClickShowPassword = (e) => {
    console.log("eeeeeeeeeeeee", e);
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleSubmit = () => {
    const data = { userName: userData.email, password: userData.password };
    handleLogin(data);
    console.log("data", data);
    let role = "user";
    role === "user" ? navigate("/designer") : navigate("/dashboard");

    // navigate("/dashboard");
    setLoading(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Welcome back
          </Typography>
          <Typography component="p" variant="body2">
            Login to the Dashboard
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email/Username"
              name="email"
              autoComplete="email"
              value={userData.email}
              autoFocus
              onChange={onChangeData}
              InputProps={{
                startAdornment: <EmailOutlined />,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              value={userData.password}
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              onChange={onChangeData}
              InputProps={{
                startAdornment: <LockOutlined />,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? "Logging in..." : "LOGIN"}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
