import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./assests/css/property-panel.css";
const App = (props) => {
  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
};

export default App;
